@mixin font-face($style-name, $file, $category:"") {
    $filepath: "../static/fonts/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
        font-display: block;
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                family: "#{$style-name}", #{$category};
            }
            @else {
                family: "#{$style-name}";
                weight: normal;
            }
        }
    }
}
@include font-face('PSLAdvertProBold', 'PSLAdvertProBold', 'serif');

@include font-face('CCShoutOutBold', 'CCShoutOutBold', 'serif');

@include font-face('PSLChocolateExtraW1Pro', 'PSLChocolateExtraW1Pro', 'serif');

@include font-face('PSLSimilanyaExtraPro', 'PSLSimilanyaExtraPro', 'serif');
