@import 'mixins.scss';
@import 'fonts.scss';

* {
    user-select: none;
    box-sizing: border-box;
}
body {
    position: relative;
    display: block;
    padding: 0px;
    margin: 0px;
    min-width: 320px;
    font-family: 'PSLSimilanyaExtraPro';
    color: #fff;
    font-size: 24px;
    background: #000;
    @media(max-width: 1024px){
        font-size: 16px;
    }
}
a, a:hover, a:active, a:focus{
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

img{
    // display: block;
    max-width: 100%;
    max-height: 100%;
}
ul, li {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    list-style-type: none;
}

.disable,
.disabled {
    filter: grayscale(100%) !important;
    cursor: default;
    pointer-events: none;
}
.grayscale{
    filter: grayscale(100%) !important;
}
.default{
    cursor: default;
    pointer-events: none;
}
.scroll-disable{
    ::-webkit-scrollbar {
        width: 0px;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent; 
    }
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
.scroll-yellow{
    ::-webkit-scrollbar {
        width: .5vw;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #cbd800;
        border-radius: 10px;
    }
}
.tooltip{
    font-size: 1.8vw;
}

.slick-next{
    width: 3%;
    height: auto;
    z-index: 1;
    right: 7%;
    top: 58%;
    transform: translate(0, -50%);
    &::before{
        font-size:0;
    }
}
.slick-prev{
    width: 3%;
    height: auto;
    z-index: 1;
    left: 7%;
    top: 58%;
    transform: translate(0, -50%);
    &::before{
        font-size:0;
    }
}
.slick-dots{
    display: none !important;
}

.videobgcontent{
    position: absolute;
    object-fit: fill;
    top: 0;
    width: 100%;
    @media only screen and (max-width: 1024px) {
        display: none;
    }
}
.videobg{
    width: 100%;
    height: auto;
    display: block;
}